<script setup lang="ts">
import { formatAmount } from '@legacy-studio/library';
import type { DealsSection } from '../core/types';
import { getNearestTimeDeal, getDealTimerInfo } from '../core/domain';
import { useOpenDealsSection } from '../core/application';
import ActiveDealTimer from './Timer.vue';
import { UiIcon } from '~/src/shared/ui';
import { Timer } from '~/src/modules/timer';

const props = defineProps<{
	section: DealsSection;
}>();

const openDealsSection = useOpenDealsSection();

const nearestTimeDeal = computed(() => getNearestTimeDeal(props.section.items));

const timer = ref<Timer | null>();

watch(
	() => nearestTimeDeal.value,
	(deal) => {
		const timerInfo = getDealTimerInfo(deal);
		timer.value = new Timer({
			type: 'clock',
			direction: 'down',
			count: timerInfo.all,
			initial: timerInfo.past,
		});
		timer.value.start();
	},
	{
		immediate: true,
	},
);
const type = computed(() => {
	return props.section.type === 'short' ? '1-5 мин' : '5+ мин';
});
</script>

<template>
	<div
		class="p-3 flex flex-col gap-4 ring-1 ring-gray-600 bg-gray-800 rounded-lg cursor-pointer"
		@click="openDealsSection(section, true)"
	>
		<div class="flex w-full gap-2 items-center">
			<div
				class="rounded-lg ring-1 ring-green-400 px-1 py-0.5 text-xs font-medium text-green-400"
			>
				{{ `${section.percent}%` }}
			</div>
			<span class="text-sm font-medium text-white">
				{{ section.asset }}
			</span>
			<span class="text-sm font-medium text-gray-500 flex-1">
				{{ type }}
			</span>
			<span class="text-sm font-medium text-gray-500 flex-1 text-right flex gap-1 items-center justify-end">
				{{ section.items.length }}
				<UiIcon
					class="text-[16px]"
					name="fill--transaction"
				/>
			</span>
		</div>

		<div class="flex gap-4 items-center justify-between w-full">
			<ActiveDealTimer
				v-if="timer"
				:value="timer.value"
				:percent="timer.percent"
			/>

			<div class="flex flex-col gap-1">
				<div class="flex items-center gap-3 justify-end">
					<span class="text-sm font-medium text-white">Сделка</span>
					<span class="text-sm font-medium text-white">
						{{ formatAmount(section.sum) }}
					</span>
				</div>
				<div class="flex items-center gap-3 justify-end">
					<span class="text-sm font-medium text-white">Выплата</span>
					<span
						class="text-sm font-medium"
						:class="{
							'text-green-400': !!section.income,
							'text-red-400': !section.income,
						}"
					>
						{{ formatAmount(section.income) }}
					</span>
				</div>
			</div>
		</div>
	</div>
</template>
