import type { CreateDealData } from '../../core/types';
import { useApi } from '@/src/services/network';

export const useCreateDeal = () => {
	const api = useApi();

	return async (data: CreateDealData) => {
		return (
			await api<{ result: number }>('/DealsHistory/createDeal/', {
				body: {
					bet_dynamics: data.direction,
					sum: data.sum,
					expiration_date: data.expirationTime,
					invoice: data.invoiceId,
					trading_asset: data.assetId,
					asset_price: data.assetPrice,
				},
				method: 'POST',
			})
		).mapRight(res => res.result);
	};
};
