import dayjs from 'dayjs';
import type { Deal } from '../types';

export const getDealTimerInfo = (item: Deal) => {
	const date1 = dayjs(item.expirationDate).utc();
	const all = date1.diff(item.startDate, 'seconds', true);
	const past = dayjs(new Date()).utc().diff(item.startDate, 'seconds', true);

	if (past > all) {
		return {
			past: 0,
			all: 0,
		};
	}

	return {
		past,
		all,
	};
};
