<script setup lang="ts">
const props = defineProps<{
	value: NumberLike;
	percent: NumberLike;
}>();

const width = ref(48);
const height = computed(() => width.value);
const strokeWidth = ref(3);

const cx = computed(() => width.value / 2);
const cy = computed(() => width.value / 2);
const r = computed(() => width.value / 2 - strokeWidth.value);
const perimeter = computed(() => Math.PI * 2 * r.value);

const strokeDasharray = computed(() => perimeter.value);
const strokeDashoffset = computed(() => {
	const value = strokeDasharray.value * ((100 - +props.percent) / 100);

	return value > 0 ? value : 0;
});
</script>

<template>
	<div class="relative">
		<div
			class="text-xs font-medium text-white absolute inset-x-0 inset-y-0 m-auto w-fit h-fit"
		>
			{{ value }}
		</div>

		<svg
			:width="width"
			:height="height"
			class="-rotate-90"
		>
			<circle
				:r="r"
				:cx="cx"
				:cy="cy"
				:style="{
					'stroke-width': strokeWidth,
				}"
				fill="none"
				class="stroke-gray-600"
			/>

			<circle
				:r="r"
				:cx="cx"
				:cy="cy"
				:style="{
					'stroke-width': strokeWidth,
					'stroke-dasharray': strokeDasharray,
					'stroke-dashoffset': strokeDashoffset,
				}"
				fill="none"
				class="stroke-primary-300"
			/>
		</svg>
	</div>
</template>
