import { saveOpenedDealsSection } from '../../infrastructure/store';
import type { DealsSection } from '../types';
import { useSidebarStore } from '~/src/widgets/terminal/store';
import { TraderDrawers } from '~/src/widgets/terminal/enum';

export const useOpenDealsSection = () => {
	const sidebarStore = useSidebarStore();
	return (section: DealsSection, active: boolean = false) => {
		saveOpenedDealsSection(section, active);
		sidebarStore.openDrawer(TraderDrawers.DetailDeal, true);
	};
};
